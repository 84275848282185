<script>

  import { onMount } from 'svelte';
  import BookingForm from "../BookingForm/BookingForm.svelte";
  import CourseTabs  from "./CourseTabs.svelte";
  import CourseList  from "./CourseList.svelte";
  import Utils from "../../utilities/utils";

  export let courseData;
  export let courseContent;
  export let cityName;
  export let courseLevel;

  let crsData, courses;
  let scrollPos, y;
  let loaded = false;
  let isOpenRegForm, bookingCourseId, bookingOnlineCourseId, tabId = 1;
  let hideList = false;  //'hide'-flag class for course-list section class

  const utils = new Utils();

  const courseContentHTML = courseContent.innerHTML;
  const singleTab = ['82', '83'].includes(courseLevel); // Define courseLevels that have a single tab only

  crsData = courseData.courses
    .filter((item) => item.onlineLevel === courseLevel)
    .map((item) => {
      return {
        levelId: item.onlineLevel,
        locationId: item.room.location,
        locationCityName: utils.filterLocationStr(item.room.locationAddress),
        startTime: parseInt(item.startEventStartTime),
        active: false,
        item: item
      };
    });


  // Sort Courses by locationId
  crsData = crsData.sort((a, b) => a.locationId - b.locationId);

  // Group Courses by locationCityName
  courses = utils.groupBy('locationCityName')(crsData);


  // values are delegated via event dispatcher from child components
  const showForm = (e) => {
    isOpenRegForm = e.detail.isOpen;      // isOpenRegForm = true
    bookingCourseId = e.detail.courseId;
    bookingOnlineCourseId = e.detail.onlineCourseId;
    hideList = true;
    scrollPos = y;
    window.scrollTo({top: 0});
  }
  const hideForm  = (e) => {
    isOpenRegForm = e.detail.isOpen;      // isOpenRegForm = false
    hideList = false;
    window.scrollTo({top: scrollPos});
  }
  const activeTab = (e) => {
    tabId = e.detail.id;
    hideList = tabId === 2;
  }

  onMount(() => {
    setTimeout(() => {
      loaded = true;
    }, 150);
  });

</script>

<svelte:window bind:scrollY={y}/>
<CourseTabs {singleTab} on:tabSelect={activeTab} on:booking={hideForm} />
<div class="relative section-wrap loading" class:loaded={loaded}>
  {#if (tabId === 2 && !isOpenRegForm) || (tabId === 1 && !isOpenRegForm && singleTab)}
    <section class="section course-content">
      <div class="container narrowed-container">
        {@html courseContentHTML}
      </div>
    </section>
  {/if}
  {#if isOpenRegForm}
    <section class="section registration-form">
      <div class="container">
        <BookingForm {bookingCourseId} {bookingOnlineCourseId} on:booking={hideForm} />
      </div>
    </section>
  {/if}
  {#if !singleTab}
    <section class="section course-list" class:hidden={hideList}>
      <div class="container">
        {#if courses !== undefined}
          <CourseList {courses} {cityName} on:booking={showForm} />
        {/if}
      </div>
    </section>
  {/if}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .section-wrap {
    min-height: 500px;
  }
  .section {
    text-align: left;
  }
  .course-list {
    &.hidden {
      display: none;
    }
  }
</style>