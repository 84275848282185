<script>
  import Utils from "../../utilities/utils";
  export let timetableItems;
  const utils = new Utils();
</script>

<div class="timetable-section">
  {#each Object.entries(utils.groupBy('location')(timetableItems)) as timetableItem}
    <h2 class="timetable-section-headline headline_2">{timetableItem[0]}</h2>
    <div class="timetable-row timetable-row-head">
      <span class="event-time">Uhrzeit</span>
      <span class="event-title">Kurs / Veranstaltung</span>
      <span class="event-location">Standort</span>
    </div>
    {#each timetableItem[1] as item}
      <div class="timetable-row">
        <span class="event-time">{item.startTime} - {item.endTime}</span>
        <span class="event-title">
            {#if item.link}
              <a href="{item.link}">{item.type}: {item.title}</a>
            {:else}
              {item.title}
            {/if}
           </span>
        <span class="event-location">{item.location}</span>
      </div>
    {/each}
  {/each}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .timetable-section-headline {
    font-weight: 300;
    margin-top: 3rem;
    @include respond-to-min($md) {
      margin-top: 4rem;
    }
    margin-bottom: 1em;
  }

  .timetable-row {
    font-size: 1.2rem;
    background: rgba(#4c475a, 0.7);
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border-radius: $border-radius-sm;
    display: grid;
    grid-column-gap: 1rem;
    @include respond-to-min($md) {
      border-radius: $border-radius;
      grid-template-columns: 165px auto 36%;
    }
    span {
      display: block;
    }
    a {
      color: #f273fb;
    }
  }

  .timetable-row-head {
    background: none;
    font-size: 0.92rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.08rem;
    opacity: 0.65;
    margin-bottom: 0;
    @include respond-to-max($md - 1px) {
      @include visuallyhidden();
    }
  }

</style>