<script>
  let iframe;
  const handle = function(event) {
    if (event.origin !== "https://tanzschule-leipzig.nimbuscloud.at") return;
    let height = parseInt(event.data) + 25;
    if (iframe !== null) {
      iframe.style.height = height + "px";
    }
  }
  window.addEventListener("message", handle, false);
</script>

<iframe
  id="ncFrame"
  title="NimbusCloud"
  src="https://tanzschule-leipzig.nimbuscloud.at/index.php?c=PublicCustomers&a=Coupons&site=3"
  class="bookingframe" bind:this={iframe}
  style="height: 500px; overflow: hidden">
</iframe>

<style type="text/scss">
  @import '../../config/mixins-svelte';

  :global {
    .bookingframe-container {
      //background: rgba($white, 0.08);
      min-height: 1500px;
      width: 100%;
      border-radius: $border-radius;
    }
  }
  .bookingframe {
    border: none;
    width:100%;
    overflow: hidden;
  }
</style>