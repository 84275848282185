<script>
  import { createEventDispatcher } from 'svelte';

  import MyDate from "../../utilities/dateUtils";
  import Utils from "../../utilities/utils";

  export let item;

  const crsUtils = new Utils();
  const today = Date.now();
  const startTime = new MyDate(parseInt(item.startEventStartTime) * 1000); // needed in ms
  const endTime = new MyDate(parseInt(item.startEventEndTime) * 1000);     // needed in ms
  const timeDiff = (parseInt(item.startEventEndTime) - parseInt(item.startEventStartTime));

  const courseShortWeekday = startTime.weekday();
  const courseWeekday = startTime.weekdayFullName();
  const courseRecurringWeekday = startTime.recurringWeekday();

  const courseDate = `${startTime.date()}.${startTime.month()}.${startTime.year()}`;
  const courseStartTime = `${startTime.hour()}:${startTime.minute()}`;
  const courseEndTime = `${endTime.hour()}:${endTime.minute()}`;
  const courseDuration = timeDiff / 60;


  const bookingOptions = (item.bookingTypes.length > 0);
  const regPossible = item.registrationPossible;

  let bookingType = [];

  if (bookingOptions) {
    bookingType = item.bookingTypes.map(itm => {
      return {
        id: itm.item,
        type: itm.itemtype,
        name: itm.name,
        price: itm.price.replace('.', ',')
      }
    }).sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  }

  let status, statusBookedOut;
  status = ((parseInt(item.startEventStartTime) * 1000) >= today) ? ' upcoming' : ' running';
  statusBookedOut = (regPossible === false) ? ' booked-out' : '';

  const dispatch = createEventDispatcher();

  const openForm = () => {
    dispatch('booking', {
      isOpen: true,
      courseId: item.courseId,
      onlineCourseId:item.onlineCourseId
    });
  }

  // mapped event items including past dates
  const eventItems = item.events.map((evtItem) => {
    return {
      start_time: parseInt(evtItem.start_time * 1000)
    };
  });

  const timeInterval = crsUtils.getTimeInterval(eventItems);

</script>


<div class="course-row">

  {#if bookingOptions && item.bookingTypes[0].itemtype === 'course'}
    <span class="weekday">{courseShortWeekday}</span>
    <span class="date">{courseDate}</span>
    <span class="time">{courseStartTime}</span>
    <span class="location">{item.room.locationName} &ndash; {item.room.name}</span>
    <span class="separator"></span>
    <span class="duration">{item.eventsCount} x {courseDuration} min</span>
    <span class="price">{item.bookingTypes[0].price.replace('.', ',')} €</span>
    <span class="info"><button class="btn-info">i</button></span>
    <span class="booking">
      {#if regPossible !== false}
        <button class="btn-booking{status}" on:click|preventDefault|stopPropagation={openForm}>buchen</button>
      {:else}
        <button class="btn-booking{statusBookedOut}">ausgeb.</button>
      {/if}
    </span>
  {/if}

  {#if bookingOptions && item.bookingTypes[0].itemtype === 'time'}
    <span class="weekday">{courseShortWeekday}</span>
    <span class="time">{courseStartTime}</span>
    <span class="location">{item.room.locationName} &ndash; {item.room.name}</span>
    <span class="separator"></span>
    <span class="duration">{courseDuration} min</span>
    <span class="price">Abo: {item.bookingTypes[0].price.replace('.', ',')} €/Mon</span>
    <span class="info"><button class="btn-info">i</button></span>
    <span class="booking">
    {#if regPossible !== false}
      <button class="btn-booking{status}" on:click|preventDefault|stopPropagation={openForm}>buchen</button>
    {:else}
      <button class="btn-booking{statusBookedOut}">ausgeb.</button>
    {/if}
    </span>
  {/if}
</div>

<style type="text/scss">

  @import '../../config/mixins-svelte';
  .course-row {
    position: relative;
    background-color: #4c475a;
    padding:0.5rem;
    font-size: 0.875rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transition: background-color 120ms ease-in-out;
    cursor: pointer;
    z-index: 100;
    &:hover {
      background-color: lighten(#4c475a, 5%);
    }
    @include respond-to-min($md) {
      font-size: 1rem;
    }
    @include respond-to-min($md) {
      padding: 0.5rem 0.75rem;
    }
  }
  span {
    display: block;
    line-height: 1.25;
    padding: 0.15rem 0;
    &.weekday {
      margin-right: 0.5rem;
      min-width:1.5rem;
      display: flex;
      align-items: center;
    }
    &.rec-weekday {
      margin-right: 0.5rem;
      min-width:1.5rem;
      display: flex;
      align-items: center;
      @include respond-to-min($ml) {
        min-width: 6rem;
      }
    }
    &.interval {
      margin-right: 1rem;
    }
    &.date {
      margin-right: 1rem;
    }
    &.time {
      margin-right: 1rem;
    }
    &.location {
      flex: 0 0 100%;
      @include respond-to-min($md) {
        flex: 1;
      }
    }
    &.separator {
      flex: 0 0 100%;
      border-bottom: rgba($white, 0.15) 1px solid;
      padding-top: 0.5rem;
      margin-bottom:0.5rem;
      @include respond-to-min($ml) {
        display: none;
      }
    }
    &.duration {
      margin: 0 1rem 0 0;
      @include respond-to-min($ml) {
        flex: initial;
        margin: 0 0 0 1rem;
        padding: 0;
      }
    }
    &.price {
      flex: 1;
      @include respond-to-min($ml) {
        flex: initial;
        margin-left: 1rem;
      }
    }
    &.info {
      margin-right: 0.5rem;
      @include respond-to-min($md) {
        margin: 0 1rem;
      }
    }
  }
  .course-events-container {
    padding-bottom: 2rem;
  }
</style>
