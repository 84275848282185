<script>
  import {activeLevel, mobileMenuToggleState} from '../../../utilities/store';

  let isActive = false;
  function toggleState() {
    isActive = !isActive;
    $mobileMenuToggleState = isActive;
    if (!isActive) $activeLevel = 0;

  }
  // add class to body-tag with toggle state
  $: document.body.classList.toggle('is-open', isActive);

</script>

<button class="nav-toggle-btn" on:click={toggleState}>
  <span></span>
</button>

<style type="text/scss">
  @import '../../../config/mixins-svelte';
  .nav-toggle-btn {
    position: absolute;
    top: 0.8rem;
    right: 1rem;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    span {
      display: block;
      width: 18px;
      height: 2px;
      background: rgba($white, 0.8);
      position: relative;
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 18px;
        height: 2px;
        background: rgba($white, 0.8);
      }
      &:before {
        top: -6px;
      }
      &:after {
        bottom: -6px;
      }
    }
    @include respond-to-min($lg) {
      display: none;
    }
    &:focus {
      border: none;
      outline: none;
    }
  }
</style>
