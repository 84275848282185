<script>
  import Select from 'svelte-select';
  import MyDate from "../../utilities/dateUtils";
  import Utils from "../../utilities/utils";
  import TimetableCourseEvents from "./TimetableCourseEvents.svelte";
  import TimetableBlockEvent from "./TimetableBlockEvent.svelte";
  import {onMount} from "svelte";

  export let timetableData;
  export let wpCourseData;

  let selection, selectedDate;
  let loaded = false;

  const utils = new Utils();
  const getProps = (levelId) => wpCourseData.filter((item) => item.courseLevel === levelId)[0];

  // Course Events
  const timetableEvents = timetableData
    .filter((item) => (item.room !== 'Online-Unterricht' && item.location !== 'Online' && item.typeId !== '13' && item.isCourseEvent === true))
    .map((item) => {
      let startTime = new MyDate(Date.parse(item.start_date));
      let endTime = new MyDate(Date.parse(item.end_date));
      return {
        timestamp: Math.round(new Date(Date.parse(item.start_date)).setHours(0, 0, 0, 0) / 1000) * 1000, // JS: timestamp for today at 00:00
        startTime: startTime.hour() + ':' + startTime.minute(),
        endTime: endTime.hour() + ':' + endTime.minute(),
        locationid: item.locationId,
        location: item.location,
        level: item.level,
        type: item.type,
        levelId: item.levelId,
        isBlockEvent: item.isBlockEvent,
        isCourseEvent: item.isCourseEvent,
        title: (getProps(parseInt(item.levelId)) === undefined) ? item.text : getProps(parseInt(item.levelId)).postTitle,
        link: (getProps(parseInt(item.levelId)) === undefined) ? false : getProps(parseInt(item.levelId)).link
      };
    });

  // Block Events
  const timetableBlockEvents = timetableData
    .filter((item) => (item.isBlockEvent === true))
    .map((item) => {
      let startTime = new MyDate(Date.parse(item.start_date));
      let endTime = new MyDate(Date.parse(item.end_date));
      return {
        ts_start: Math.round(new Date(Date.parse(item.start_date)).setHours(0, 0, 0, 0) / 1000) * 1000, // JS: timestamp for today at 00:00
        ts_end: Math.round(new Date(Date.parse(item.end_date)).setHours(0, 0, 0, 0) / 1000) * 1000,
        startTime: startTime.hour() + ':' + startTime.minute(),
        endTime: endTime.hour() + ':' + endTime.minute(),
        locationid: item.locationId,
        location: item.location,
        level: item.level,
        type: item.type,
        levelId: item.levelId,
        isBlockEvent: item.isBlockEvent,
        isCourseEvent: item.isCourseEvent,
        title: (getProps(parseInt(item.levelId)) === undefined) ? item.text : getProps(parseInt(item.levelId)).postTitle,
        link: (getProps(parseInt(item.levelId)) === undefined) ? false : getProps(parseInt(item.levelId)).link
      };
    });


  // create selectable items for filter select
  const filterSelect = (data) => {
    let filterSelectItems = [];
    data.forEach((item, index) => {
      if (item !== undefined) {
        let startTime = new MyDate(item);
        filterSelectItems[index] = {
          value: index,
          label: `${startTime.weekdayFullName()}, ${startTime.date()}. ${startTime.monthFullName()} ${startTime.year()}`,
          timestamp: item
        };
      }
    });
    return filterSelectItems;
  }

  // handle the selection
  const handleSelect = (event) => {
    selection = event.detail;
    loaded = false;
    setTimeout(() => {
      loaded = true;
    }, 300);
  }

  // reset selection to default (--> today)
  const handleClear = () => {
    selection = undefined;
    loaded = false;
    setTimeout(() => {
      loaded = true;
    }, 300);
  }

  // get the next seven days for filter select
  const selectableDays = new MyDate().nextSevenDays();

  // rearranged courses grouped by day
  const arrangedTimetable = utils.groupBy('timestamp')(timetableEvents.sort((a, b) => a.locationid - b.locationid));

  $: selectedDate = (selection === undefined) ? new MyDate().tsToday() : selection.timestamp;

  onMount(() => {
    setTimeout(() => {
      loaded = true;
    }, 120);
  });


</script>

<div class="filter-select event-time-filter">
  <Select
    items={filterSelect(selectableDays)}
    placeholder="Heute"
    on:select={handleSelect}
    on:clear={handleClear}
    inputStyles="font-family: 'Roboto', sans-serif" />
</div>

<div class="loading" class:loaded>
  {#each Object.entries(arrangedTimetable) as timetableItems}
     {#if parseInt(timetableItems[0]) === selectedDate}
       <TimetableCourseEvents timetableItems={timetableItems[1]} />
     {/if}
  {/each}
  {#if timetableBlockEvents.length > 0}
    <TimetableBlockEvent {timetableBlockEvents} {selectedDate}/>
  {/if}
</div>
