<script>
  import MyDate from "../../utilities/dateUtils";

  export let timetableBlockEvents;
  export let selectedDate;

</script>

<h2 class="headline_2 timetable-section-headline">Blocktermine</h2>
<div class="blockevents">
  {#each timetableBlockEvents as timetableBlockEvent}
    {#if selectedDate >= timetableBlockEvent.ts_start && selectedDate <= timetableBlockEvent.ts_end}

      <span>
      {new MyDate(timetableBlockEvent.ts_start).date()}.
      {new MyDate(timetableBlockEvent.ts_start).month()}.
      {new MyDate(timetableBlockEvent.ts_start).year()} -
      {new MyDate(timetableBlockEvent.ts_end).date()}.
      {new MyDate(timetableBlockEvent.ts_end).month()}.
      {new MyDate(timetableBlockEvent.ts_end).year()}
      </span>
      <span>
      {timetableBlockEvent.title}

      </span>

    {/if}
  {/each}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';

  .timetable-section-headline {
    font-weight: 300;
    margin-top: 3rem;
    @include respond-to-min($md) {
      margin-top: 4rem;
    }
    margin-bottom: 1em;
  }

  .blockevents {
    font-size: 1.2rem;
    background: rgba(#4c475a, 0.7);
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    border-radius: $border-radius-sm;
    display: grid;
    grid-column-gap: 1rem;
    @include respond-to-min($md) {
      border-radius: $border-radius;
      grid-template-columns: 16rem auto;
    }
  }
</style>