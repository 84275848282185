<script>
  import { courseData } from '../../utilities/store';
  import Image from "../Image/Image.svelte";

  export let target;
  let activeCourseLevels, courseTileListItems;
  const courseTileList = target.children[1].children;

  $: {
    if (!Array.isArray($courseData)) {
      activeCourseLevels = $courseData.courses
        .map((item) => item.onlineLevel);
    }
    if (activeCourseLevels !== undefined) {
      courseTileListItems = [...courseTileList]
        .filter((item) => activeCourseLevels.includes(item.getAttribute('data-level')))
        .map((item) => {
        return {
          level: item.getAttribute('data-level'),
          previewImageHTML: item.querySelector('.preview-image').innerHTML,
          href: item.querySelector('.grid-tile-label-course a').href,
          title: item.querySelector('.grid-tile-label-course a').innerText,
          target: item.children[0].children[0]
        }
      });
    }
  }

  target.classList.remove('hidden');
  Array.from(target.children).forEach((node) => node.remove());

</script>

{#if activeCourseLevels !== undefined}
  <ul class="grid grid-course-tiles">
    {#each courseTileListItems as listItem}
      <li class="grid-tile">
        <div class="grid-tile-inner">
          <div class="image">
            <div class="preview-image">{@html listItem.previewImageHTML}</div>
            <Image target={listItem.target} />
          </div>
          <div class="grid-tile-label-container">
            <div class="grid-tile-label-course">
              <h3 class="headline_3"><a href="{listItem.href}" class="stretched-link">{listItem.title}</a></h3>
            </div>
          </div>
        </div>
      </li>
  {/each}
  </ul>
{/if}