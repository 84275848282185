<script>

  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let bookingCourseId, bookingOnlineCourseId;

  const close = () => dispatch('booking', { isOpen: false });

  let iframe;
  const handle = function(event) {
    if (event.origin !== "https://tanzschule-leipzig.nimbuscloud.at") return;
    let height = parseInt(event.data) + 25;
    if (iframe !== null) {
      iframe.style.height = height + "px";
    }
  }

  window.addEventListener("message", handle, false);

</script>

<div class="container close-container">
  <button on:click={close} class="close-btn">
    <span>&times;</span>
    <span class="sr-only">Formular schließen</span>
  </button>
</div>

<div class="bookingframe-container">
  <iframe
    id="ncFrame"
    title="NimbusCloud"
    src="https://tanzschule-leipzig.nimbuscloud.at/index.php?c=PublicCustomers&a=Registration&item=course&id={bookingCourseId}&online_id={bookingOnlineCourseId}&site=2"
    class="bookingframe" bind:this={iframe}
    style="height: 5000px">
  </iframe>
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .bookingframe-container{
    background: rgba($white, 0.08);
    min-height: 1500px;
    width: 100%;
    border-radius: $border-radius;
    overflow: hidden;
  }
  .bookingframe {
    border: none;
    width:100%;
  }
  .close-container {
    display: flex;
    justify-content: flex-end;
  }

  .close-btn {
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    opacity: 0.75;
    transform: translateY(-1.2rem);
    span {
      font-family: 'Roboto', sans-serif;
      font-size: 3rem;
      font-weight: 100;
      color: $white;
    }
    @include respond-to-min($md) {
      width: 2rem;
      height: 2rem;
      transform: translateY(-2rem);
      span {
        font-size: 4rem;
      }
    }
  }
</style>