<script>
  import { courseData, eventData } from '../../utilities/store';
  import EventList from './EventList.svelte';
  export let target;

	/*
	$: {
		if (!Array.isArray($courseData) && Array.isArray($eventData)) {
			console.log($eventData);
			console.log($courseData.courses);
		}
  }
  */
</script>

<!-- note $eventData is an array here -->
<!--
{#if !Array.isArray($courseData) && Array.isArray($eventData) && $eventData.length > 0}
{#if !Array.isArray($courseData) && Array.isArray($eventData)}
<EventList courseData={$courseData} eventData={$eventData} {target} />
{/if}
-->

{#if !Array.isArray($courseData) && Array.isArray($eventData) && $eventData.length > 0}
<EventList courseData={$courseData} eventData={$eventData} {target} />
{/if}
