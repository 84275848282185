<script>
  import MyDate from "../../utilities/dateUtils";
  export let slide;
  let startTime = new MyDate(parseInt(slide.start) * 1000);
  let endTime = new MyDate(parseInt(slide.end) * 1000);
</script>

<div class="events-item frontpage-events-item">

  <div class="events-item-date">
    <div class="events-item-date-inner">
      <span class="date">{startTime.date()}</span>
      <span class="month">{startTime.monthName()}</span>
    </div>
  </div>

  <div class="events-item-info">
    <h2 class="title headline_3">{slide.title}</h2>
    <span class="location">{slide.locationName}</span>
    <span class="duration">{startTime.hour()}:{startTime.minute()} - {endTime.hour()}:{endTime.minute()} Uhr</span>
  </div>

  {#if (slide.itemType === 'courseEvent')}
  <div class="events-item-booking">
    <a class="btn-booking normal" href="events/?ref=home&id={slide.courseId}&oid={slide.onlineCourseId}">buchen</a>
  </div>
  {/if}

  {#if (slide.itemType === 'ticketEvent')}
    <div class="events-item-booking">
      <a href="{slide.ticketsLink}" target="_blank" class="btn-booking">Tickets</a>
    </div>
  {/if}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .events-item {
    text-align: left;
  }
  .events-item-info {
    font-size:1rem;
  }
  .title {
    font-size:1.5rem;
  }
</style>