<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let singleTab;

  let current = 'tab1';

  const closeregForm = () => {
    dispatch('booking', { isOpen: false });
  }
  const tab1 = () => {
    closeregForm();
    dispatch('tabSelect', { id: 1 });
    current = 'tab1';
    window.scrollTo({top: 0});
  }
  const tab2 = () => {
    closeregForm();
    dispatch('tabSelect', { id: 2 });
    current = 'tab2';
    window.scrollTo({top: 0});
  }

</script>

<div class="course-tabs">
  <div class="container">
    <ul class="course-tabs-items">
      {#if singleTab === true}
        <li class="inhalte"><span role="button" class="active">Kursinhalte/Preise</span></li>
      {:else}
        <li class="termine"><span role="button" class:active={current === 'tab1'} on:click="{tab1}">Kurstermine</span></li>
        <li class="inhalte"><span role="button" class:active={current === 'tab2'} on:click="{tab2}">Kursinhalte/Preise</span></li>
      {/if}
    </ul>
  </div>
</div>


<style type="text/scss">
  @import '../../config/mixins-svelte';
  .course-tabs {
    margin-top: -2rem;
    position: relative;
    z-index: 20;
    @include respond_to-min($sm) {
      margin-top: -2.5rem;
    }
    @include respond_to_all(padding,(
      md: 0 $vspace-md,
      lg: 0 $vspace-lg
    ));
  }
  .course-tabs-items {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1px;
    span {
      display: block;
      width: 100%;
      text-align: center;
      cursor: pointer;
      height: 2rem;
      line-height: 2rem;
      font-size: 0.85em;
      background: rgba(#352c49, 0.65);
      &.active {
        background: #352c49;
      }
      border-top-left-radius: $border-radius-sm;
      border-top-right-radius: $border-radius-sm;
    }
    @include respond_to-min($sm) {
      grid-column-gap: 3px;
      span {
        height: 2.5rem;
        line-height: 2.5rem;
      }
    }
    @include respond_to-min($md) {
      width: 6rem;
      margin-left: 260px;
      span {
        width: 200px;
        padding: 0 1.5rem;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
    @include respond_to-min($lg) {
      margin-left: 300px;
      span {
        width: 240px;
        padding: 0 1.5rem;
      }
    }
  }
</style>