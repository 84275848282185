<script>
  export let subLevel2;
  let isOpen = false;
  const toggle = () => isOpen = !isOpen;

</script>

<li id={subLevel2.id} class={subLevel2.classes}>
  {#if subLevel2.classes.indexOf('menu-item-has-children') !== -1}

    <a href="{subLevel2.url}" class="nav-link" on:click={toggle}>{subLevel2.title}</a>
    <button aria-expanded={isOpen} on:click={toggle}>
      <i class="icon icon-chevron-right" class:is-open={isOpen}></i>
      <span class="sr-only">Sub-Menü offen</span>
    </button>

    {#if subLevel2.hasOwnProperty('submenu')}

      <div class:is-open={isOpen}>
        <ul class="sub-menu level-3">
          {#each subLevel2.submenu.items as subLevel3}
            {#if subLevel3.hide === "0"}
              <li id={subLevel3.id} class={subLevel3.classes}>
                <a href="{subLevel3.url}" class="nav-link">{subLevel3.title}</a>
              </li>
            {/if}
          {/each}
        </ul>
      </div>
    {/if}
  {:else}
    <a href="{subLevel2.url}" class="nav-link">{subLevel2.title}</a>
  {/if}
</li>