<script>
  import IntersectionObserver from './IntersectionObserver.svelte';
  import ImageSrc from './ImageSrc.svelte';

  export let target = false;

  let image = target.children[0].children[0];

  let imgProps = {
    src: image.getAttribute('src'),
    alt: image.getAttribute('alt'),
    srcset: image.getAttribute('data-srcset'),
    sizes: JSON.parse(image.getAttribute('data-sizes')),
    width: image.getAttribute('width'),
    height: image.getAttribute('height'),
    classes: image.classList
  }

</script>


<IntersectionObserver once={true} let:intersecting={intersecting}>
  {#if intersecting}
    <ImageSrc {imgProps} />
  {/if}
</IntersectionObserver>