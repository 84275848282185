<script>
  import { courseData } from '../../utilities/store';
  import Course from './Course.svelte';

  export let target;
  const courseContent = target.children[0];
  const cityName = target.getAttribute('data-city');
  const courseLevel = target.getAttribute('data-level');

  // Remove Original Elements From DOM (Rendered By Backend)
  Array.from(target.children).forEach(node => node.remove());

</script>

{#if !Array.isArray($courseData)}
<Course
  courseData={$courseData}
  courseContent={courseContent}
  cityName={cityName}
  courseLevel={courseLevel}
/>
{/if}