<script>
  import { onMount } from 'svelte';
  import Masonry from 'masonry-layout';

  export let target;
  let msnry;

  onMount(() => {
    setTimeout(function(){
      msnry = new Masonry(target, {
        itemSelector: '.msnry-grid-item',
        columnWidth: '.msnry-grid-sizer',
        percentPosition: true
      });
    }, 500);
  });

</script>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  :global {
    .msnry-grid {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem;
      margin-bottom: -1.875rem;
    }

    .msnry-grid:after {
      content: '';
      display: block;
      clear: both;
    }

    .msnry-grid-sizer,
    .msnry-grid-item {
      @include respond_to_all(width,(
        xs: 100%,
        sm: 50%,
        ml: 33.3333%
      ));
      padding: 0 0.9375rem;
    }

    .msnry-grid-item {
      float: left;
      margin-bottom: 1.875rem;
    }
  }
</style>