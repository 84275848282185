<script>
  import { locationData } from '../../utilities/store';
  import Locations from './Locations.svelte';

  export let target;
  const wpLocationData = target.children[0].children;

  // Remove Original Elements From DOM (Rendered By Backend)
  Array.from(target.children).forEach((node) => node.remove());

</script>

{#if !Array.isArray($locationData)}
  <Locations locationData={$locationData} wpLocationData={wpLocationData} />
{/if}