<script>
  import { slide } from 'svelte/transition';
  import CourseRow from "./CourseRow.svelte";
  import CourseEventDetails from "./CourseEventDetails.svelte";

  export let courseItem;
  let isOpen = false;
</script>

<div class="course-row-container" on:click={() => isOpen = !isOpen}>
  <CourseRow item={courseItem.item} on:booking />
  {#if isOpen}
    <div class="course-events-container" in:slide|local={{duration: 250 }} out:slide|local={{duration: 250 }}>
      <CourseEventDetails item={courseItem.item}/>
    </div>
  {/if}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .course-row-container {
    border-radius: $border-radius-sm;
    @include respond-to-min($md) {
      border-radius: $border-radius;
    }
    margin-bottom: 0.5rem;
    overflow: hidden;
    position: relative;
  }
</style>