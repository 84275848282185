<script>
  import { activeLevel } from '../../../utilities/store.js';
  import Utils from "../../../utilities/Utils.js";

  // also exported by recursive calling of this component (svelte:self)
  export let navData;
  export let courseData;
  export let mobileNavItems = navData.mobile;
  export let courseNavItems = navData.courses;
  export let courseItems = courseData.courses;
  export let currentLevelName = '';
  export let depth = 0;

  let loaded = false;

  // course data coming from NimbusCloud
  const crsUtils = new Utils();
  let crsData = courseItems.map((item) => {
    return {
      typeId: item.onlineType,
      typename: item.onlineTypeName,
      levelId: item.onlineLevel,
      levelName: item.onlineLevelName,
      locationId: item.room.location,
      locationCityName: crsUtils.filterLocationStr(item.room.locationAddress)
    };
  });

  // create array with unique course level ids (currently active NimbusCloud course levels)
  const crsLevels = [...new Map(crsData.map(item => [item["levelId"], item["levelId"]])).values()];

  // Call Walker Once On Top Level Only
  if (depth === 0) {
    walkNavObj(mobileNavItems);
  }

  // Walker Function for Modifiing NavList Object
  function walkNavObj(navobj) {
    // Loop NavList Object ...
    Object.keys(navobj).forEach(function (key) {

      // Assign Property Value
      const value = navobj[key];

      // Check If Property Value Is A Nested Object
      if (value instanceof Object) {

        if (value.title !== undefined) {
          // Search Courses Object For 'title' Property ...
          const match = courseNavItems.items.find(function (node) {
            if (node.title !== undefined && node.title === value.title) {
              return true;
            }
          });

          // Add Submenus Objects
          if (match !== undefined && match.hasOwnProperty('submenu')) {
            let updatedClasses = value.classes.concat(' menu-item-has-children'); // Change Value Of 'classes' Property
            value.submenu = match.submenu;    //Add 'submenu' Property
            value.classes = updatedClasses;   //Update 'classes' Property
          }
        }
        // Update hide property
        if (value.hide !== undefined) {
          if ('courseLevel' in value && !crsLevels.includes(value.courseLevel)) {
            value.hide = "1";
          }
        }
        // Walk Recursively
        walkNavObj(value);
      }
    });
  }

  function openSubmenu(e) {
    let nextUL = e.target.nextElementSibling;
    document.querySelectorAll('.active').forEach(i => {
      i.classList.remove('active')
    });
    nextUL.classList.add('active', 'open');
    $activeLevel = Number(e.target.value);
  }
  function closeSubmenu(e) {
    let thisUL = e.target.parentNode.parentNode;
    let parentUL = thisUL.parentNode.parentNode;
    document.querySelectorAll('.active').forEach(i => {
      i.classList.remove('active')
    });
    thisUL.classList.remove('open');
    parentUL.classList.add('active');
    parentUL.scrollTop = 0;
    $activeLevel = Number(e.target.value);
  }
  function setTabindex(level) {
    return level === depth ? 0 : -1;
  }

</script>

{#if mobileNavItems !== undefined}

  <ul class="{mobileNavItems.classes} level-{depth}" class:active={depth === 0}>

    {#if mobileNavItems.classes.indexOf('sub-menu') !== -1}
      <li class="back">
        <button on:click={e => closeSubmenu(e)} value={depth - 1} tabindex={setTabindex($activeLevel)}>
          <i class="icon icon-chevron-left"></i>
          <span class="sr-only">Sub-Menü schließen</span>
        </button>
        {currentLevelName}
        <span></span>
      </li>
    {/if}

    {#each mobileNavItems.items as listItem}
      {#if listItem.hide === "0"} <!-- flag for avalability in nimbuscloud -->
        <li id="{listItem.id}" class="{listItem.classes}">

          <a href="{listItem.url}" tabindex={setTabindex($activeLevel)}>{listItem.title}</a>

          {#if listItem.classes.indexOf('menu-item-has-children') !== -1}
            <button on:click={openSubmenu} value={depth + 1} tabindex={setTabindex($activeLevel)}>
              <i class="icon icon-chevron-right"></i>
              <span class="sr-only">Sub-Menü offen</span>
            </button>
          {/if}
          {#if listItem.submenu !== undefined}
            <svelte:self
              depth={depth + 1}
              currentLevelName={listItem.title}
              mobileNavItems={listItem.submenu}
              {courseNavItems}
              {courseData}
              {navData}
            />
          {/if}
        </li>
      {/if}
    {/each}
  </ul>
{/if}

<style type="text/scss">

  @import '../../../config/mixins-svelte';

  ul {
    @include rem(padding, 130px 0 60px 0);
    @include respond-to-min($sm) { @include rem(padding, 160px 0 80px 0); }
    @include respond-to-min($md) { @include rem(padding, 180px 0 90px 0); }
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(top, #270638 0%, #460a6d 20%, #516395 100%);
    transition: left 0.2s ease-in-out;
    &.sub-menu {
      left: 100%;
      &.open {
        left: 0;
      }
    }
    &.active {
      overflow-y: auto;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0,0,0,0.25);
    margin: 0 5%;
    @include rem(min-height, 45px);
    margin-bottom: 0.25rem;
    border-radius: $border-radius-sm;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include rem(width, 35px);
      @include rem(min-height, 45px);
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      i {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        color: rgba($white, 0.7);
        font-size: 1.5rem;
      }
    }

    a {
      flex: 1;
      text-align: left;
      text-decoration: none;
      padding: 0 1rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 1rem;
      letter-spacing: 0;
      text-transform: initial;
      @include respond-to-min($md) {
        font-size: 1.25rem;
      }
    }

    &.back {
      text-transform: uppercase;
      font-size: 0.85rem;
      letter-spacing: 0.08rem;
      @include rem(min-height, 35px);
      @include rem(margin-bottom, 15px);
      button {
        @include rem(min-height, 35px);
      }
      > span {
        display: block;
        @include rem(width, 35px);
      }
    }
  }

</style>