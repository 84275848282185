<script>
  import {onMount} from "svelte";
  import Image from '../Image/Image.svelte';
  import Utils from "../../utilities/utils";

  export let locationData;
  export let wpLocationData;
  let loaded = false;

  const utils = new Utils();

  // Compare function
  const getProps = (locationId) => locationData.locations.filter((item) => parseInt(item.id) === locationId)[0];

  // Locations from Wordpress
  const wpLocations = Array.from(wpLocationData).map((item) => {
    let locId = parseInt(item.getAttribute('data-location-id'));
    return {
      locationId: locId,
      title: item.children[1].innerText,
      link: item.children[1].getAttribute('href'),
      img: {
        src: item.children[0].getAttribute('src'),
        sizes: JSON.parse(item.children[0].getAttribute('data-sizes')),
        srcset: item.children[0].getAttribute('data-srcset'),
        alt: item.children[0].getAttribute('alt'),
        className: item.children[0].className,
        width: item.children[0].getAttribute('width'),
        height: item.children[0].getAttribute('height')
      },
      previewImg: item.children[0].children[0].innerHTML,
      imgTarget: item.children[0],
      address: (getProps(locId) !== undefined) ? getProps(locId).address : false,
      rooms: (getProps(locId) !== undefined) ? getProps(locId).rooms : false,
      cityName: (getProps(locId) !== undefined) ? utils.filterLocationStr(getProps(locId).address) : false
    }
  });

  // Sort Courses by locationId
  wpLocations.sort((a, b) => a.locationId - b.locationId);

  // rearrange courses
  const arrangedLocations = utils.groupBy('cityName')(wpLocations);

  onMount(() => {
    setTimeout(() => {
      loaded = true;
    }, 50);
  });

</script>

<div class="loading" class:loaded>
  {#each Object.entries(arrangedLocations) as locationItems}
    <h2 class="locations-section-headline">{locationItems[0]}</h2>
    {#each locationItems[1] as locationItem}
      <div class="locations-item">
        <div class="thumbnail">
          <div class="image">
            <div class="preview-image">
              {@html locationItem.previewImg}
            </div>
            <Image target={locationItem.imgTarget} />
          </div>
        </div>
        <div class="content">
          <h3 class="title">{locationItem.title}</h3>
          <div>{locationItem.address}</div><br>
          <a href="https://www.google.com/maps/search/?api=1&query={locationItem.address}" target="_blank" rel="nofollow">Bei Google Maps finden</a>
        </div>
      </div>
    {/each}
  {/each}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  :global {
    .locations-item {
      .preview-image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .loaded-image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .locations-item {
    background: rgba($black, 0.15);
    overflow: hidden;
    border-radius: $border-radius-sm;
    height: 100%;
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    display: grid;
    align-items: start;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    grid-template-columns: 35% auto;

    @include respond-to-min($md) {
      grid-template-columns: 16% auto;
      grid-column-gap: 2rem;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: $border-radius;
    }

    @include respond-to-min($lg) {
      padding: 1.25rem;
      grid-column-gap: 2.5rem;
    }

    .title {
      font-weight: 300;
      margin: 0;
    }
  }
  .thumbnail {
    position: relative;
    margin: 0 !important;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }
  .locations-section-headline {
    font-weight: 300;
    margin-top: 3rem;
    @include respond-to-min($md) {
      margin-top: 4rem;
    }
    margin-bottom: 1em;
  }
</style>