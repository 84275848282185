<script>
  import { createEventDispatcher } from 'svelte';
  import Utils from "../../../utilities/Utils";
  import MegaMenuSubmenu from "./MegaMenuSubmenu.svelte";

  export let navData;
  export let courseData;
  export let locationData;

  const crsUtils = new Utils();
  const desktopNavItems = navData.desktop;
  const courseNavItems = navData.courses;
  const courses = courseData.courses;
  const locations = locationData.locations;

  // create arrays with unique course level ids and unique locations
  // (currently active NimbusCloud course levels)
  const crsData = courses.map((item) => {
    return {
      typeId: item.onlineType,
      typename: item.onlineTypeName,
      levelId: item.onlineLevel,
      levelName: item.onlineLevelName,
      locationId: item.room.location,
      locationCityName: crsUtils.filterLocationStr(item.room.locationAddress)
    };
  });
  const crsLevels = [...new Map(crsData.map(item => [item["levelId"], item["levelId"]])).values()];
  //const crsLocationIds = [...new Map(crsData.map(item => [item["locationId"], item])).values()];
  const crsLocationsCities = [...new Map(crsData.map(item => [item["locationCityName"], item])).values()];

  const dispatch = createEventDispatcher();
  const closeMegamenu = () => dispatch('closeMegamenu', {isOpen: false});

  // Walker Function for Modifiing NavList Object
  function walkNavObj(navobj) {
    // Loop NavList Object ...
    Object.keys(navobj).forEach(function (key) {

      // Assign Property Value
      const value = navobj[key];

      // Check If Property Value Is A Nested Object
      if (value instanceof Object) {
        // Update hide property
        if (value.hide !== undefined) {

          if ('courseLevel' in value && !crsLevels.includes(value.courseLevel)) {
            value.hide = "1";
          }

          // Exeptions for 'Online-Tanzschule' and 'Privatkurse'
          // Ids are Menu Item Ids
          //if (value.id === '7331' || value.id === '7335') {
          if (value.id === '7335') {
            value.hide = "0";
          }
        }
        // Walk Recursively
        walkNavObj(value);
      }
    });
  }

  courseNavItems.classes = 'sub-menu level-1';
  walkNavObj(courseNavItems.items);
</script>

<div class="dropdownmenu-inner megamenu-inner">

  <ul class={courseNavItems.classes}>
    {#each courseNavItems.items as item}
      <li id={item.id} class={item.classes}><a href="{item.url}" class="nav-link">{item.title}</a>
        {#if item.hasOwnProperty('submenu')}
          <ul class="sub-menu level-2">
            {#each item.submenu.items as subLevel2}
              {#if subLevel2.hide === "0"} <!-- flag for avalability in nimbuscloud -->
                <MegaMenuSubmenu {subLevel2} />
              {/if}
            {/each}
          </ul>
        {/if}
      </li>
    {/each}
  </ul>

  <div class="wp-block-buttons btn-group">
    <div class="btn btn-medium btn-grey wp-block-button">
      <a href="/kurse/stundenplan/" class="wp-block-button__link">Kursplan</a>
    </div>
    <div class="btn btn-medium btn-grey wp-block-button">
      <a href="/kurse/" class="wp-block-button__link">Kursübericht</a>
    </div>
  </div>

  <div></div>

  <ul class="location-list">
    {#each crsLocationsCities as locationItem, i}
      {#if locationItem.locationCityName !== undefined}
        <li>{#if i === 0}<span>Alle Kurse in: &nbsp;</span> {/if}<a href="/kurse/#{locationItem.locationCityName}" on:click={closeMegamenu}>{locationItem.locationCityName}</a></li>
      {/if}
    {/each}
  </ul>

</div>
