<script>
  import { mobileMenuToggleState, navData, courseData } from '../../../utilities/store';
  import MobileNavigation from './MobileNavigation.svelte';

  let loaded = false;

  $: {
    if ($mobileMenuToggleState === true) {
      setTimeout(() => {
        loaded = true;
      }, 25);
    }
    else {
      loaded = false;
    }
  }
</script>


{#if $mobileMenuToggleState && !Array.isArray($navData) && !Array.isArray($courseData)}
  <div class="mobilenav-container loading" class:loaded>
    <nav class="main-navigation mobile-nav">
      <MobileNavigation
        navData={$navData}
        courseData={$courseData} />
    </nav>
  </div>
{/if}

<style type="text/scss">

  @import '../../../config/mixins-svelte';

  .mobilenav-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    text-align: center;
    z-index: 100;
    background: linear-gradient(top, #270638 0%, #460a6d 20%, #516395 100%);
  }

</style>