<script>
  import { courseData, locationData } from '../../utilities/store';
  import CourseTiles from './CourseTiles.svelte';

  export let target;
  const sections = target.querySelectorAll('.course-tile-list'); // DOM array

  // Remove Original Elements From DOM (Rendered By Backend)
  Array.from(target.children).forEach((node) => node.remove());

</script>

{#if !Array.isArray($courseData) && !Array.isArray($locationData)}
  <CourseTiles courseData={$courseData} locationData={$locationData} sections={sections} />
{/if}
