<script>

  export let target;

  const protocol = location.protocol;
  const hostname = location.hostname;

  const socialItems = Array.from(target.children[0].children).map((item) => {
    return {
      name: item.children[0].innerText,
      link: item.children[0].href
    }
  });

  // Remove Original Elements From DOM (Rendered By Backend)
  Array.from(target.children).forEach((node) => node.remove());

</script>

{#each socialItems as socialItem}
  <a href="{socialItem.link}" class="icon" title="{socialItem.name}" role="button" aria-label="Facebook" target="_blank">
    <svg aria-hidden="true" focusable="false">
      <use xlink:href="{protocol}//{hostname}/wp-content/themes/tanzschule/assets/icons.svg#icon-{socialItem.name.toLowerCase()}"></use>
    </svg>
  </a>
{/each}