<script>
  import CourseRowContainer from "./CourseRowContainer.svelte";
  export let courses, cityName;

  // Sort Courses by startTime
  Object.entries(courses).forEach(location => location[1].sort((a, b) => a.startTime - b.startTime));

</script>

{#each Object.entries(courses) as courseItems}
  {#if cityName !== ''}
    {#if courseItems[0] === cityName}
      <h2 class="headline_2">{courseItems[0]}</h2>
      {#each courseItems[1] as courseItem}
        <CourseRowContainer {courseItem} on:booking />
      {/each}
    {/if}
  {:else}
    <h2 class="headline_2">{courseItems[0]}</h2>
    {#each courseItems[1] as courseItem}
      <CourseRowContainer {courseItem} on:booking />
    {/each}
  {/if}
{/each}

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .headline_2 {
    font-weight: 300;
  }
</style>