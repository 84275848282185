import { writable } from 'svelte/store';

export const mobileMenuToggleState  = writable(false);
export const mainNavItems           = writable(false);
export const activeLevel            = writable(0);
export const openTeaser             = writable(0);

export const lightbox = writable({
  isOpen: false,
  id: false,
  props: false
});

export const cookieConsentStates = writable({
  necessary: false,
  analytics: false,
  tracking:  false,
  marketing: false,
  externalMedia: false
});

export const navData        = writable([]);
export const courseData     = writable([]);
export const wpCourseData   = writable([]);
export const locationData   = writable([]);
export const eventData      = writable([]);
export const timetableData  = writable([]);

export const timetableSelectedItem = writable();
