<script>
  export let slide;
</script>

<div class="slide-inner">
  {@html slide.innerHTML}
</div>

<style lang="scss">
  @import '../../config/mixins-svelte';
  :global {
    .frontpage-swiper {
      .wp-block-cover {
        height: 100%;
        position: relative;
      }
      .wp-block-cover__video-background,
      .wp-block-cover__image-background {
        width: 100vw;
        height: 100%;
        object-fit: cover;
      }
      .wp-block-cover__inner-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem 1rem 10vh 1rem;
        text-align: center;

        h1 {
          @include headline_1;
          font-weight: 700;
          margin: 0;
          letter-spacing: 0;
          color: $white;
          text-shadow: 0 0 15px rgba(0,0,0,0.7);
        }
        h2 {
          @include headline_1;
          font-weight: 700;
          margin: 0;
          letter-spacing: 0;
          color: $white;
          text-shadow: 0 0 15px rgba(0,0,0,0.7);
        }
        h3 {
          @include headline_2;
          margin: 0;
        }
        p {
          margin: 0.5rem 0 2rem 0;
          color: $white;
          text-shadow: 0 0 15px rgba(0,0,0,0.85);
          font-size: 1.2em;
        }
      }

      .wp-block-buttons {
        margin: 0;
      }

      h1 + div.wp-block-buttons,
      h2 + div.wp-block-buttons {
        margin-top: 2rem !important;
      }
      .has-background-dim {
        background: linear-gradient(180deg,
          rgba(0,0,0,0.8) 0%,
          rgba(0,0,0,0.55) 15%,
          rgba(0,0,0,0.15) 30%,
          rgba(0,0,0,0.3) 100%) !important;
        @include respond-to-min($lg) {
          background: linear-gradient(180deg,
            rgba(0,0,0,0.8) 0%,
            rgba(0,0,0,0.55) 20%,
            rgba(0,0,0,0.15) 40%,
            rgba(0,0,0,0.3) 100%) !important;
        }
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .slide-inner {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  .slide-background,
  .slide-overlay,
  .slide-content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slide-background {
    top: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      display: block;
      min-width: var(--min-width);
      height: 100vh;
      @include respond-to-min($lg) {
        width: 100vw;
        height: auto;
      }
    }
  }

  .slide-overlay {
    top: 0;
    z-index: 1;
    background: linear-gradient(180deg,
      rgba(0,0,0,0.8) 0%,
      rgba(0,0,0,0.55) 15%,
      rgba(0,0,0,0) 30%,
      rgba(0,0,0,0) 100%);
    @include respond-to-min($lg) {
      background: linear-gradient(180deg,
        rgba(0,0,0,0.8) 0%,
        rgba(0,0,0,0.55) 20%,
        rgba(0,0,0,0) 40%,
        rgba(0,0,0,0) 100%);
    }
  }
  .slide-content {
    bottom: 0;
    z-index: 2;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    @include respond_to_all(padding, (
      xs: 6vh 1.25rem,
      sm: 6vh 1.187rem,
      md: 6vh 2.5rem,
      lg: 6vh 4rem
    ));
  }

</style>
