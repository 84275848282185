<script>
  import Mediabreak from '../../utilities/Mediabreak.svelte';
  import DesktopNav from './Desktop/DesktopNav.svelte';
  import NavToggle  from './Mobile/NavToggle.svelte';

  document.getElementById('mainNav').remove();

</script>

<Mediabreak query="(min-width: 1024px)" let:matches>
  {#if matches}
    <DesktopNav />
  {:else}
    <NavToggle />
  {/if}
</Mediabreak>
