class Utils {

  constructor() {

    // Group Object by Property
    this.groupBy = key => array => array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

    // Filter Location String: Split by PLZ and return pure city name
    this.filterLocationStr = (str) => {
      if (str.length > 0) {
        return str.split(/\d{5}\s/)[1].trim();
      }
    }

    // helper function: get the element with the highest occurrence in an array
    this.highestOccurance = arr => (arr || []).reduce((acc,el) => {
      acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1;
      acc.max = acc.max ? acc.max < acc.k[el] ? el : acc.max : el;
      return acc;
    }, { k:{} }).max;


    // return the time interval of recurring events
    this.getTimeInterval = (eventArray) => {
      let prevTime = 0;
      let arr = []
      eventArray.forEach((itm, index) => {
        let itemTime = itm.start_time;
        let timeDiff = itemTime - prevTime;

        if (timeDiff === 604800000) arr[index] = 7;         // 7 days
        else if (timeDiff === 1209600000) arr[index] = 14;  // 14 days
        else arr[index] = 0;

        prevTime = itemTime;
      });
      return this.highestOccurance(arr);
    }

    /*
    makeURLSlug = (str) => {
      let modifiedStr;
      // replace all sprecial chars
      modifiedStr = str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '');
      //console.log(modifiedStr);
      //return modifiedStr;
    }
    */
  }
}

export default Utils;
