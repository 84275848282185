<script>
  import { onMount } from 'svelte';
  export let imgProps;

  let orientation;
  let src    = imgProps.src;
  let srcset = imgProps.srcset;
  let alt    = imgProps.alt;
  let width  = imgProps.width;
  let height  = imgProps.height;

  let ratio = width / height;
  if (ratio > 1) orientation = 'landscape';
  if (ratio < 1) orientation = 'portrait';
  if (ratio === 1) orientation = 'square';

  let thisImage;
  let imgLoaded = false;

  onMount(() => {
    thisImage.onload = () => {
      imgLoaded = true;
    }
  });

</script>

<img src={src} alt={alt} class={orientation} class:imgLoaded bind:this={thisImage} srcset={srcset} />