<script>
  import { mobileMenuToggleState, navData, courseData, locationData } from '../../../utilities/store';
  import DesktopNavigation from './DesktopNavigation.svelte';
  let desktopNavActive = false;

  //Set MobileNav to default
  $mobileMenuToggleState = false;
  document.body.classList.remove('is-open');

</script>

<nav class="main-nav svelte-navigation">
  {#if !Array.isArray($navData) && !Array.isArray($courseData) && !Array.isArray($locationData)}
    <DesktopNavigation
      navData={$navData}
      courseData={$courseData}
      locationData={$locationData}
    />
  {/if}
</nav>