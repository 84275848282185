<script>
  import {onMount} from "svelte";
  import { Swiper, SwiperSlide } from 'swiper/svelte';
  import SwiperCore, { Navigation, Pagination } from 'swiper';
  import FrontpageSwiperItem from './FrontpageSwiperItem.svelte';

  SwiperCore.use([Navigation, Pagination]);

  export let target = false;
	export let swiperId = false;

  const slides = Array.from(target.querySelectorAll('.wp-block-getwid-tabs__tab-content'));

  let loaded = false;
  let loop = slides.length > 1;
  let pagination = (slides.length > 1) ? { el: '.swiper-pagination-' + swiperId } : false;
  let navigation = (slides.length > 1) ? { prevEl: '.prev-' + swiperId, nextEl: '.next-' + swiperId } : false;

  onMount(() => {
    loaded = true;
  });

  Array.from(target.children).forEach((node) => { node.remove() });

</script>

<div class="loading-container" class:loaded>
  <Swiper
    spaceBetween={0}
    slidesPerView={1}
    watchOverflow={true}
    loop={loop}
    pagination={pagination}
    navigation={navigation}>
    {#each slides as slide}
      <SwiperSlide>
        <FrontpageSwiperItem {slide} />
      </SwiperSlide>
    {/each}
  </Swiper>

  {#if slides.length > 1}
  <div class="swiper-prev prev-{swiperId}" role="button" aria-label="Previous Slide">
    <svg aria-hidden="true" focusable="false">
      <use xlink:href="/wp-content/themes/tanzschule/assets/icons.svg#icon-chevron-left"/>
    </svg>
  </div>
  <div class="swiper-next next-{swiperId}" role="button" aria-label="Next Slide">
    <svg aria-hidden="true" focusable="false">
      <use xlink:href="/wp-content/themes/tanzschule/assets/icons.svg#icon-chevron-right"/>
    </svg>
  </div>
  <div class="swiper-pagination swiper-pagination-{swiperId}"></div>
  {/if}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  :global {
    .hero {
      //Swiper Navigation
      .swiper-prev,
      .swiper-next {
        position: absolute;
        top: calc(50% - 20px);
        width: 2.5rem;
        height: 2.5rem;
        @include respond-to-min($md) {
          top: 0;
          height: 100%;
          width: 4rem;
        }
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          fill: $white;
          color: $white;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 1.25rem;
          @include respond-to-min($md) {
            width: 3rem;
            height: 3rem;
            background-color: rgba($white, 0.25);
            transition: background-color 200ms ease-in-out;
            top: 0;
            border-radius: 1.5rem;
          }
        }
        &:hover svg {
          @include respond-to-min($md) {
            background-color: rgba($white, 0.5);
          }
        }
      }
      .swiper-prev { left: 0 };
      .swiper-next { right: 0 };

      //Swiper Pagination
      .swiper-pagination {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        width: 100%;
        @include rem(height, 35px);
        z-index: 3;
      }
      .swiper-pagination-bullet {
        background: $white !important;
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
        margin: 0 2px;
        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
  }
</style>
