<script>
  import { Swiper, SwiperSlide } from 'swiper/svelte';
  import SwiperCore, { Navigation, Pagination } from 'swiper';
  import FrontpageEventSwiperItem from './FrontpageEventSwiperItem.svelte';
  import MyDate from "../../utilities/dateUtils";
  SwiperCore.use([Navigation, Pagination]);

  export let target;
  export let swiperId;
  export let courseData;
  export let eventData;

  const courses = courseData.courses;
  const events = eventData;

  // merge events and sort it by timestamp
  const crsData = courses.filter((item) => parseInt(item.onlineType) === 9).map((item) => {
    let startTime = new MyDate(parseInt(item.startEventStartTime) * 1000);
    return {
      itemType: 'courseEvent',
      timestamp: parseInt(item.startEventStartTime) * 1000,
      title: item.onlineLevelName,
      description: item.description,
      start: item.startEventStartTime,
      end: item.startEventEndTime,
      month: startTime.monthFullName() + ' ' + startTime.year(),
      locationName: item.room.locationName,
      locationAddress: item.room.locationAddress,
      locationRoom: item.room.name,
      courseId: item.courseId,
      onlineCourseId: item.onlineCourseId,
      registrationPossible: item.registrationPossible,
      ticketLink: false
    };
  });
  const evtData = events.map((item) => {
    let startTime = new MyDate(parseInt(item.begin) * 1000);
    return {
      itemType: 'ticketEvent',
      timestamp: parseInt(item.begin) * 1000,
      title: item.name,
      description: item.description,
      start: item.begin,
      end: item.end,
      month: startTime.monthFullName() + ' ' + startTime.year(),
      locationName: item.locationName,
      locationAddress: false,
      locationRoom: false,
      coueseId: false,
      onlineCourseId: false,
      registrationPossible: false,
      ticketsLink: item.ticketsLink
    }
  });
  const mergedEventData = [...crsData, ...evtData].sort((a, b) => a.timestamp - b.timestamp);

  let maxSlides = target.getAttribute('data-maxnum');
  let pagination = { el: '.swiper-pagination-' + swiperId };
  let navigation = { prevEl: '.prev-' + swiperId, nextEl: '.next-' + swiperId };

</script>

    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      breakpoints={{
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 3,
        }
      }}
      watchOverflow={true}
      loop={false}
      pagination={pagination}
      navigation={navigation}>

      {#each mergedEventData as slide, index}
        {#if index < maxSlides}
          <SwiperSlide>
            <FrontpageEventSwiperItem {slide} />
          </SwiperSlide>
        {/if}
      {/each}
    </Swiper>
    {#if mergedEventData.length > 1}
      <div class="swiper-prev prev-{swiperId}" role="button" aria-label="Previous Slide">
        <svg aria-hidden="true" focusable="false">
          <use xlink:href="wp-content/themes/tanzschule/assets/icons.svg#icon-chevron-left"/>
        </svg>
      </div>
      <div class="swiper-next next-{swiperId}" role="button" aria-label="Next Slide">
        <svg aria-hidden="true" focusable="false">
          <use xlink:href="wp-content/themes/tanzschule/assets/icons.svg#icon-chevron-right"/>
        </svg>
      </div>
      <div class="swiper-pagination swiper-pagination-{swiperId}"></div>
    {/if}

<style type="text/scss">
  @import '../../config/mixins-svelte';
  :global {
    .frontpage-events {
      position: relative;
      height: 220px;
      @include respond-to-min($md) {
        margin-bottom: 2rem;
      }
      @include respond-to-min($lg) {
        margin-bottom: 3rem;
      }

      //Swiper Navigation
      .swiper-prev,
      .swiper-next {
        position: absolute;
        top: 0;
        width: 2.5rem;
        height: 100%;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include respond-to-min($md) {
          width: 3rem;
        }
        svg {
          fill: $white;
          color: $white;
          width: 2.5rem;
          height: 2.5rem;
          @include respond-to-min($md) {
            width: 3rem;
            height: 3rem;
          }
        }
      }
      .swiper-prev {
        left: 0;
        @include respond-to-min($sm) { left: -2.15rem; }
        @include respond-to-min($md) { left: -3rem; }
      }
      .swiper-next {
        right: 0;
        @include respond-to-min($sm) { right: -2.15rem; }
        @include respond-to-min($md) { right: -3rem; }
      }
      //Swiper Pagination
      .swiper-pagination {
        position: absolute;
        bottom: -2.5rem;
        left: 0;
        width: 100%;
        @include rem(height, 35px);
        z-index: 3;
      }
      .swiper-pagination-bullet {
        background: $white !important;
        opacity: 0.3;
        transition: opacity 0.3s ease-in-out;
        margin: 0 2px;
        &.swiper-pagination-bullet-active {
          opacity: 1;
        }
      }
    }
    .frontpage-events-inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      @include rem(height, 250px);
      background: rgba($white, 0.075);
      border-radius: $border-radius;
      &.loaded {
        display: none;
      }
    }
  }
</style>