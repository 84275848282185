<script>
  import { onMount } from 'svelte';
  import Select from 'svelte-select';
  import Utils from "../../utilities/utils";
  import Image from "../Image/Image.svelte";

  export let sections;
	export let courseData;
	export let locationData;

  let loaded = false;
	let courseSections = []; // Create the new array for output in HTML Block
	let selectedItemParam = '';
	let filteredCourses, selection, hash, hashLocationId, hashLocation, selectedValue;

  const utils  = new Utils();

  // Process Courses
  const crsData = courseData.courses.map((item) => {
    return {
			onlineCourseId: item.onlineCourseId,
      levelId: item.onlineLevel,
      locationId: item.room.location,
      locationCityName: utils.filterLocationStr(item.room.locationAddress)
    };
  });

  // get city names from active courses
	const locationCities = courseData.courses.map((item) => {
		return {
			id: item.room.location,
			cityName: utils.filterLocationStr(item.room.locationAddress)
		}
	});

  // get unique city names
	const cities = [...new Map(locationCities.map(item => [item["cityName"], item])).values()];

	const filterSelect = (data) => {
		let filterSelectItems = [];
		data.forEach((item, index) => {
			if (item.cityName !== undefined) {
				filterSelectItems[index] = {
					label: item.cityName,
          value: item.cityName
				};
			}
		});

		// returs sorted by value (locationId)
		return filterSelectItems.sort((a, b) => a.value - b.value);
	}

	const handleClear = () => {
		selection = undefined;
		selectedItemParam = '';
		removeHash();
		loaded = false;
		setTimeout(() => {
			loaded = true;
		}, 300);
	}
	const handleSelect = (event) => {
		selection = event.detail;
		if (selection.value !== undefined) {
			selectedItemParam = '?l=' + selection.label;
		}
		removeHash();
		loaded = false;
		setTimeout(() => {
			loaded = true;
		}, 300);
	}
	const handleHash = () => {
		hash = window.location.hash;
		hashLocation = decodeURI(hash.split('#')[1]);
		filterSelect(cities).forEach(element => {
			if (element.value === hashLocation) {
				selection = element;
				selectedItemParam = '?l=' + selection.label;
			}
		});
	}
	const removeHash = () => {
		let uri = window.location.toString();
		if (uri.indexOf("#") > 0) {
			let cleanUri = uri.substring(0, uri.indexOf("#"));
			window.history.replaceState({}, document.title, cleanUri);
		}
	}

  $: {

		// Filter $courseData by location name as needed
		if (selection === undefined) {
			filteredCourses = crsData;
		}
		else {
			filteredCourses = crsData.filter(item => item.locationCityName === selection.value);
		}

		// loop sections (dom)
		sections.forEach((section, i) => {


			// populate output array with defaults
			courseSections[i] = {
				headline: section.children[0].innerText,
				show: true,
				items: []
			}

			// loop li-items (dom)
			Array.from(section.children[1].children).forEach((sectionItem, j) => {

				// filter items (if dom and cloud items equals by course level id)
				let filteredItems = filteredCourses.filter( item => item.levelId === sectionItem.getAttribute('data-level'));

				// set 'show' to false if the first (j) and filtered item returns nothing
				// if (j === 0 && filteredItems.length === 0) courseSections[i].show = false;

				// populate item data
				if (filteredItems.length > 0) {
					courseSections[i].items[j] = {
						previewImage: sectionItem.querySelector('.image').innerHTML,
						href: sectionItem.querySelector('.grid-tile-label-course a').href,
						title: sectionItem.querySelector('.grid-tile-label-course a').innerText,
						target:  sectionItem.querySelector('.image')
					};
				}
			});
		});

  }

	onMount(() => {
		handleHash();
		window.addEventListener('hashchange', handleHash, false);

		setTimeout(() => {
			loaded = true;
		}, 25);
	});

</script>

<div class="filter-select course-location-filter">
  <Select
    on:select={handleSelect}
    on:clear={handleClear}
    selectedValue={selection}
    items={filterSelect(cities)}
    listPlacement={'bottom'}
    placeholder="Kurse in Ihrer Stadt ..."
    inputStyles="font-family: 'Roboto', sans-serif" />
</div>


<div class="loading" class:loaded>
  {#each courseSections as courseSection}
    {#if courseSection.items.length > 0}
      <div class="course-section">
        <h2 class="headline_2 course-section-headline">{courseSection.headline}</h2>
        <ul class="grid grid-course-tiles">
          {#each courseSection.items as courseSectionItem}
            {#if courseSectionItem !== undefined}
              <li class="grid-tile">
                <div class="grid-tile-inner">
                  <div class="image">
                    {@html courseSectionItem.previewImage}
                    <Image target={courseSectionItem.target} />
                  </div>
                  <div class="grid-tile-label-container">
                    <div class="grid-tile-label-course">
                      <h3 class="headline_3"><a href="{courseSectionItem.href}{selectedItemParam}" class="stretched-link">{courseSectionItem.title}</a></h3>
                    </div>
                  </div>
                </div>
              </li>
            {/if}
          {/each}
        </ul>
      </div>
    {/if}
  {/each}
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .course-section {
    position: relative;
    z-index: 0;
  }
  .course-section-headline {
    font-weight: 300;
    margin-top: 3rem;
    @include respond-to-min($md) {
      margin-top: 4rem;
    }
    margin-bottom: 1em;
  }
</style>
