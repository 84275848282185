<script>
  import { onMount } from 'svelte';
  import Select from 'svelte-select';
  import MyDate from "../../utilities/dateUtils";
  import Utils from "../../utilities/utils";
  import EventListItem from './EventListItem.svelte';
  import BookingForm from "../BookingForm/BookingForm.svelte";

  export let target;
  export let courseData;
  export let eventData;

  // handle clicks from homepage events swiper
  const refer = target.getAttribute('data-ref');
  const referCourseId = target.getAttribute('data-id');
  const referOnlineCourseId = target.getAttribute('data-oid');

  const utils = new Utils();
  const courses = courseData.courses;
  const events = eventData;

  // merge events and sort it by timestamp
  const crsData = courses.filter((item) => parseInt(item.onlineType) === 9).map((item) => {
    let startTime = new MyDate(parseInt(item.startEventStartTime) * 1000);
    return {
      itemType: 'courseEvent',
      timestamp: parseInt(item.startEventStartTime) * 1000,
      title: item.onlineLevelName,
      description: item.description,
      start: item.startEventStartTime,
      end: item.startEventEndTime,
      month: startTime.monthFullName() + ' ' + startTime.year(),
      locationName: item.room.locationName,
      locationAddress: item.room.locationAddress,
      locationRoom: item.room.name,
      courseId: item.courseId,
      onlineCourseId: item.onlineCourseId,
      registrationPossible: item.registrationPossible,
      ticketLink: false
    };
  });

  const evtData = events.map((item) => {
    let startTime = new MyDate(parseInt(item.begin) * 1000);
    return {
      itemType: 'ticketEvent',
      timestamp: parseInt(item.begin) * 1000,
      title: item.name,
      description: item.description,
      start: item.begin,
      end: item.end,
      month: startTime.monthFullName() + ' ' + startTime.year(),
      locationName: item.locationName,
      locationAddress: false,
      locationRoom: false,
      coueseId: false,
      onlineCourseId: false,
      registrationPossible: false,
      ticketsLink: item.ticketsLink
    }
  });

  //console.log(crsData);

  const mergedData = [...crsData, ...evtData].sort((a, b) => a.timestamp - b.timestamp);
  const mergedEventData = mergedData.filter((el) => {
    return el.timestamp >= Date.now();
  });





  let loaded = false;
  let showFilter = true;
  let bookingCourseId, bookingOnlineCourseId, selection, showRegistrationForm, scrollPos, y;
  let selectableEventMonths, arrangedEvents;

  // Get All Locations From Active Courses - (City Names Only)
  // Create array with properties for location select
  const filterSelect = (data) => {
    let filterSelectItems = [];
    data.forEach((item, index) => {
      if (item !== undefined) {
        filterSelectItems[index] = {
          value: index,
          label: item
        };
      }
    });
    return filterSelectItems;
  }

  // values are delegated via event dispatcher from child components
  const showForm = (e) => {
    if (e) {
      showRegistrationForm = e.detail.isOpen;  // -> isOpenRegForm = true
      bookingCourseId = e.detail.courseId;
      bookingOnlineCourseId = e.detail.onlineCourseId;
      showFilter = false;
      scrollPos = y;
      window.scrollTo({top: 0});
    }
  }
  const hideForm = (e) => {
    showRegistrationForm = e.detail.isOpen; // -> isOpenRegForm = false
    showFilter = true;
    window.scrollTo({top: scrollPos});
  }

  // Handle Filter Select
  const handleSelect = (event) => {
    selection = event.detail;
    loaded = false;
    setTimeout(() => {
      loaded = true;
    }, 300);
  }
  const handleClear = () => {
    selection = undefined;
    loaded = false;
    setTimeout(() => {
      loaded = true;
    }, 300);
  }

  onMount(() => {
    // coming from homepage events swiper ...
    if (refer !== null) {
      showRegistrationForm = true;
      showFilter = false;
      bookingCourseId = referCourseId;
      bookingOnlineCourseId = referOnlineCourseId
    }
    setTimeout(() => {
      loaded = true;
    }, 120);
  });

  // store selectable month names (used by filter select)
  selectableEventMonths = [...new Map(mergedEventData.map(item => [item["month"], item.month])).values()];

  // rearrange courses - group by Month
  arrangedEvents = utils.groupBy('month')(mergedEventData);

</script>

<div class="filter-select event-time-filter" class:show={showFilter}>
  <Select
    items={filterSelect(selectableEventMonths)}
    on:select={handleSelect}
    on:clear={handleClear}
    placeholder="Veranstaltungen im Monat ..."
    listPlacement={'bottom'}
    inputAttributes={{readonly:'readonly'}}
    inputStyles="font-family: 'Roboto', sans-serif" />
</div>

<div class="loading" class:loaded>
  {#if showRegistrationForm}
    <div class="events-section registration-form">
      <BookingForm {bookingCourseId} {bookingOnlineCourseId} on:booking={hideForm} />
    </div>
  {:else}
    <div class="events-section">
      {#each Object.entries(arrangedEvents) as eventItems}
        {#if selection !== undefined}
          {#if eventItems[0] === selection.label}
            <h2 class="events-section-headline headline_2">{eventItems[0]}</h2>
            {#each eventItems[1] as eventItem}
              <EventListItem {eventItem} on:booking={showForm} />
            {/each}
          {/if}
        {:else}
          <h2 class="events-section-headline headline_2">{eventItems[0]}</h2>
          {#each eventItems[1] as eventItem}
            <EventListItem {eventItem} on:booking={showForm} />
          {/each}
        {/if}
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  @import '../../config/mixins-svelte';

  .filter-select {
    visibility: hidden;
    &.show {
      visibility: inherit;
    }
  }

  .events-section-headline {
    font-weight: 300;
    margin-top: 3rem;
    @include respond-to-min($md) {
      margin-top: 4rem;
    }
    margin-bottom: 1em;
  }
</style>
