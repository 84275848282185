<script>
  import { onMount } from 'svelte';
  import Select from 'svelte-select';

  export let target;

  let year = target.getAttribute('data-year');
  let month = target.getAttribute('data-month');

  let items = Array.from(target.children);

  let selection;

  const filterSelect = (data) => {
    let filterSelectItems = [];
    data.forEach((item, index) => {
      if (item !== undefined) {
        filterSelectItems[index] = {
          value: index,
          y: item.getAttribute('href').split('/')[3],
          m: item.getAttribute('href').split('/')[4],
          href: item.getAttribute('href'),
          label: item.getAttribute('title')
        };
      }
    });
    return filterSelectItems;
  }

  // Handle Filter Select
  const handleSelect = (event) => {
    selection = event.detail;
    //document.location.href = selection.href;
    console.log(selection);
  }

  const setSelect = () => {
    selection = {
      "y": "2021",
      "value": 2,
      "m": "04",
      "href": "https://www.tanzschule.wp/2021/04/",
      "label": "April 2021"
    }
  }

  onMount(() => {
    setSelect();
  });


  // Remove Original Elements From DOM (Rendered By Backend)
  Array.from(target.children).forEach((node) => node.remove());

</script>

<Select
  items={filterSelect(items)}
  placeholder="Archive ..."
  on:select={handleSelect}
  inputStyles="font-family: 'Roboto', sans-serif" />

