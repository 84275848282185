<script>
  import { onMount } from 'svelte';
  import { courseData, eventData } from '../../utilities/store';
  import FrontpagesEventSwiper from './FrontpageEventsSwiper.svelte';

  export let target;
  export let swiperId;

  let loaded = false;

  onMount(() => {
    setTimeout(() => {
      loaded = true;
    }, 120);
  });

</script>

{#if !Array.isArray($courseData) && Array.isArray($eventData)}
  <div class="loading" class:loaded>
    <FrontpagesEventSwiper courseData={$courseData} eventData={$eventData} {target} {swiperId} />
  </div>
{/if}
