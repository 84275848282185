import { navData, courseData, wpCourseData, locationData, eventData, timetableData } from './store';

const protocol = location.protocol;
const hostname = location.hostname;

const navData_Url        = protocol + '//' + hostname + '/wp-content/json/nav.json';
const wpCourseData_Url   = protocol + '//' + hostname + '/wp-content/json/wpcourses.json';
const courseData_Url     = protocol + '//' + hostname + '/wp-content/json/courses.json';
const locationData_Url   = protocol + '//' + hostname + '/wp-content/json/locations.json';
const timetableData_Url  = protocol + '//' + hostname + '/wp-content/json/timetable.json';
const eventData_Url      = protocol + '//' + hostname + '/wp-content/json/events.json';

fetch(navData_Url)
  .then(response => response.json())
  .then(data => {
    navData.set(data.type);
  }).catch(error => {
  console.log(error);
  return [];
});

fetch(courseData_Url)
  .then(response => response.json())
  .then(data => {
    courseData.set(data.content);
  }).catch(error => {
  console.log(error);
  return [];
});

fetch(wpCourseData_Url)
  .then(response => response.json())
  .then(data => {
    wpCourseData.set(data);
  }).catch(error => {
  console.log(error);
  return [];
});

fetch(locationData_Url)
  .then(response => response.json())
  .then(data => {
    locationData.set(data.content);
  }).catch(error => {
  console.log(error);
  return [];
});

fetch(eventData_Url)
  .then(response => response.json())
  .then(data => {
    eventData.set(data.events);
  }).catch(error => {
  console.log(error);
  return [];
});

fetch(timetableData_Url)
  .then(response => response.json())
  .then(data => {
    timetableData.set(data.content);
  }).catch(error => {
  console.log(error);
  return [];
});

export default {}
