class dateUtils {
  constructor(timestamp) {
    this.time = new Date(timestamp);
    this.monthNames = ["Jan", "Feb", "März", "Apr", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez"];
    this.monthFullNames = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];
    this.weekdayNames = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
    this.weekdayFullNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
    this.recurringWeekdays = ["Sonntags", "Montags", "Dienstags", "Mittwochs", "Donnerstags", "Freitags", "Samstags"];
  }

  year() {
    return this.time.getFullYear();
  }
  month() {
    return ("0" + (this.time.getMonth() + 1)).slice(-2);
  }
  monthName() {
    return this.monthNames[this.time.getMonth()];
  }
  monthFullName() {
    return this.monthFullNames[this.time.getMonth()];
  }
  date() {
    return ("0" + this.time.getDate()).slice(-2);
  }
  weekday() {
    return this.weekdayNames[this.time.getDay()];
  }
  weekdayFullName() {
    return this.weekdayFullNames[this.time.getDay()];
  }
  recurringWeekday() {
    return this.recurringWeekdays[this.time.getDay()];
  }
  hour() {
    return ("0" + this.time.getHours()).slice(-2);
  }
  minute() {
    return ("0" + this.time.getMinutes()).slice(-2);
  }

  // return today's timestamp at 00:00
  tsToday() {
    return Math.round(new Date().setHours(0, 0, 0, 0) / 1000) * 1000;
  }

  nextSevenDays() {
    //let today = Math.round(new Date().setHours(0, 0, 0, 0) / 1000) * 1000; // JS: timestamp for today at 00:00
    let today = this.tsToday();
    let timeOfDay = 24 * 60 * 60 * 1000; // one day in ms
    let dayArr = [];
    for (var i = 0; i < 8; i++) {
      dayArr[i] = today + (timeOfDay * i);
    }
    dayArr.shift();
    return dayArr;
  }
}
export default dateUtils;
