<script>
  import './utilities/jsonData';
  import 'swiper/swiper-bundle.min.css';
  import NavBar from './components/navigation/NavBar.svelte';
  import MobileNavContainer from './components/Navigation/Mobile/MobileNavContainer.svelte';
  import SocialNav from './components/Navigation/SocialNav.svelte';
  import Image from './components/Image/Image.svelte';
  import CoursesContainer from './components/Courses/CoursesContainer.svelte';
  import CourseTileSection from './components/Courses/CourseTileSection.svelte';
  import CourseContainer from './components/Course/CourseContainer.svelte';
  import TimetableContainer from './components/Timetable/TimetableContainer.svelte';
  import FrontpageSwiper from './components/FrontpageSwiper/FrontpageSwiper.svelte';
  import FrontpageEventsSwiperContainer from './components/FrontpageEventSwiper/FrontpageEventSwiperConatiner.svelte';
  import EventsContainer from './components/Events/EventsContainer.svelte';
  import LocationsContainer from './components/Locations/LocationsContainer.svelte';
  import ArchiveFilter from './components/ArchiveFilter/ArchiveFilter.svelte';
  import MasonryArchive from './components/MasonryArchive/MasonryArchive.svelte';
  import BookingFormCoupons from './components/BookingForm/BookingFormCoupons.svelte';


  // Navbar Component
  if (document.querySelector('#navBar')) {
    let navBar = document.getElementById('navBar');
    new NavBar({
      target: navBar
    });
  }

  // Frontpage Hero Swiper Component
  if (document.querySelector('.frontpage-swiper')) {
    let frontpageSwiper = document.querySelector('.frontpage-swiper');
    new FrontpageSwiper({
      target: frontpageSwiper,
      props: {
        target: frontpageSwiper,
        swiperId: 'fpswp'
      }
    });
  }

  // Frontpage Events Swiper Component
  if (document.querySelector('#frontpageEventsSwiper')) {
    let frontpageEventsSwiperContainer = document.getElementById('frontpageEventsSwiper');
    new FrontpageEventsSwiperContainer({
      target: frontpageEventsSwiperContainer,
      props: {
        target: frontpageEventsSwiperContainer,
        swiperId: 'fpevtswp'
      }
    });
  }

  // Courses Container Component
  if (document.querySelector('#coursesContainer')) {
    let coursesContainer = document.getElementById('coursesContainer');
    new CoursesContainer({
      target: coursesContainer,
      props: {
        target: coursesContainer
      }
    });
  }

  // Single Course Container Component
  if (document.querySelector('#singleCourseContainer')) {
    let singleCourseContainer = document.getElementById('singleCourseContainer');
    new CourseContainer({
      target: singleCourseContainer,
      props: {
        target: singleCourseContainer
      }
    });
  }

  // Timetable Container Component
  if (document.querySelector('#timetableContainer')) {
    let timetableContainer = document.getElementById('timetableContainer');
    new TimetableContainer({
      target: timetableContainer
    });
  }

  // Events Container Component
  if (document.querySelector('#eventsContainer')) {
    let eventsContainer = document.getElementById('eventsContainer');
    new EventsContainer({
      target: eventsContainer,
      props: {
        target: eventsContainer
      }
    });
  }

  // Locations Container Component
  if (document.querySelector('#locationsContainer')) {
    let locationsContainer = document.getElementById('locationsContainer');
    new LocationsContainer({
      target: locationsContainer,
      props: {
        target: locationsContainer
      }
    });
  }

  // Archive Filter Component
  if (document.querySelector('#archiveFilter')) {
    let eventList = document.getElementById('archiveFilter');
    new ArchiveFilter({
      target: eventList,
      props: {
        target: eventList
      }
    });
  }

  // Booking Form Coupons Component
  if (document.querySelector('#bookingFormCoupons')) {
    let bookingFormCoupons = document.getElementById('bookingFormCoupons');
    new BookingFormCoupons({
      target: bookingFormCoupons
    });
  }

  // Social Nav Component
  if (document.querySelector('#socialNav')) {
    let socialNav = document.getElementById('socialNav');
    new SocialNav({
      target: socialNav,
      props: {
        target: socialNav
      }
    });
  }

  // Masonry Component
  Array.from(document.querySelectorAll('.msnry-grid')).forEach((node) => new MasonryArchive({
    target: node,
    props: {target: node}
  }));

  // Image Component
  Array.from(document.querySelectorAll('.image')).forEach((node) => new Image({
    target: node,
    props: {target: node}
  }));

  // Course Tile Section (Shortcode)
  Array.from(document.querySelectorAll('.course-tile-list.post-page')).forEach((node) => new CourseTileSection({
    target: node,
    props: {target: node}
  }));

</script>

<MobileNavContainer />
