<script>
  import {onMount} from 'svelte';
  import {Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink} from 'sveltestrap';
  import MegaMenu from "./MegaMenu.svelte";
  import SubMenu from "./SubMenu.svelte";

  export let navData;
  export let courseData;
  export let locationData;

  const desktopNavData = navData.desktop;
  const courses = courseData.courses;
  const locations = locationData.locations;

  // update desktopNavItems -> Kurse -> 'classes' Property
  const navListKurse = desktopNavData.items[0];
  navListKurse.classes = navListKurse.classes.concat(' megamenu');

  let isOpen = false;
  let thisOpen = [];

  let dropdown = document.getElementsByClassName('dropdown');
  let linkElements = document.querySelectorAll('a');

  const closeMenu = (e) => {
    thisOpen[0] = e.detail.isOpen;
    dropdown[0].classList.remove('show');
    dropdown[0].children[1].classList.remove('show');
  }

  const show = (e) => {
    e.target.classList.add('show');
    e.target.children[1].classList.add('show');
  }

  const hide = (e) => {
    e.target.classList.remove('show');
    e.target.children[1].classList.remove('show');
  }

  onMount(() => {
    for (let i = 0; i < dropdown.length; ++i) {
      thisOpen[i] = false;
    }
    // Add Additional Hover Function
    for (var i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("mouseenter", show);
      dropdown[i].addEventListener("mouseleave", hide);
    }
  });
</script>


<Nav class="main-navigation">
  {#each desktopNavData.items as listItem, i}
    {#if listItem.classes.indexOf('menu-item-has-children') !== -1}
      <Dropdown nav class="{listItem.classes}" isOpen={thisOpen[i]}>
        <NavLink href="{listItem.url}">{listItem.title}</NavLink>
      <!--
      <Dropdown nav class="{listItem.classes}" isOpen={thisOpen[i]} toggle={() => (thisOpen[i] = !thisOpen[i])}>
        <DropdownToggle nav>{listItem.title}</DropdownToggle>
      -->
        {#if listItem.classes.indexOf('megamenu') !== -1}
          <DropdownMenu class="dropdown-megamenu">
            <div class="dropdown-inner">
             <MegaMenu {navData} {courseData} {locationData} on:closeMegamenu={closeMenu}/>
            </div>
          </DropdownMenu>
        {:else}
          <DropdownMenu class="dropdown-submenu">
            <div class="dropdown-inner">
              <SubMenu submenu={listItem.submenu} />
            </div>
          </DropdownMenu>
        {/if}
      </Dropdown>
    {:else}
      <NavItem class={listItem.classes}>
        <NavLink href="{listItem.url}">{listItem.title}</NavLink>
      </NavItem>
    {/if}
    {#if i === 1}
      <li>&nbsp;</li>
    {/if}
  {/each}
</Nav>