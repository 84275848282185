<script>
  import Utils from '../../utilities/utils';
  import MyDate from "../../utilities/dateUtils";

  export let item;


  const utils = new Utils();
  const now = Date.now();
  const getTime = (ts) => new MyDate(parseInt(ts) * 1000);

  const bookingTypes = item.bookingTypes.map(itm => {
    return {
      name: itm.name,
      price: itm.price.replace('.', ',')
    }
  });

  // map event items -> return object
  const eventItemsReturn = (evtItem) => {
    return {
      start_time: parseInt(evtItem.start_time * 1000),
      end_time:   parseInt(evtItem.end_time * 1000),
      weekday:    getTime(evtItem.start_time).weekday(),
      date:  `${getTime(evtItem.start_time).date()}.${getTime(evtItem.start_time).month()}.${getTime(evtItem.start_time).year()}`,
      begin: `${getTime(evtItem.start_time).hour()}:${getTime(evtItem.start_time).minute()}`,
      end:   `${getTime(evtItem.end_time).hour()}:${getTime(evtItem.end_time).minute()}`
    };
  }

  // mapped event items including past dates
  const eventItems = item.events.map((evtItem) => eventItemsReturn(evtItem));

  // mapped event items - only upcoming dates
  const upcomingEventItems = item.events.filter(evtItem => parseInt(evtItem.start_time) * 1000 > now).map((evtItem) => eventItemsReturn(evtItem));

  // get event time interval of event items array
  const timeInterval = utils.getTimeInterval(eventItems);

</script>

<div class="course-events">
  <div class="course-events-header">
    <div>

      <span class="course-title">{item.onlineTypeExternalName} &ndash; {item.onlineLevelExternalName}</span>
      {#if item.isEndlessCourse === '0'}
        <span class="course-duration">
        {getTime(item.startEventStartTime).date()}. {getTime(item.startEventStartTime).monthFullName()} {getTime(item.startEventStartTime).year()} -
        {getTime(item.lastEvent.end_time).date()}. {getTime(item.lastEvent.end_time).monthFullName()} {getTime(item.lastEvent.end_time).year()}
        </span>
        {#if parseInt(item.startEventStartTime) * 1000 < now}
          <span class="course-running-info">Dieser Kurs läuft bereits!</span>
        {/if}

      {:else}
        <span class="course-duration">
        Fortlaufender Kurs<br>
        {getTime(item.startEventStartTime).recurringWeekday()} - wöchentlich/14-täglich <br class="break">(mit Ausnahmen)<br>
        {getTime(item.startEventStartTime).hour()}:{getTime(item.startEventStartTime).minute()} -
        {getTime(item.startEventEndTime).hour()}:{getTime(item.startEventEndTime).minute()} Uhr
        </span>
        <span class="course-options">
          <strong>Buchungsoptionen:</strong><br>
          {#each bookingTypes as bookingType}
            {bookingType.name}: {bookingType.price} € <br>
          {/each}
        </span>
      {/if}
    </div>
    <div>
      <span class="location-name">{item.room.locationName}</span>
      <span class="location-address">{item.room.locationAddress}<br>{item.room.name}</span>
    </div>
  </div>
  {#if item.isEndlessCourse === '0'}
  <div class="course-events-main">
    <h3>Kurstermine</h3>
    <ul>
      {#each eventItems as eventItem, index}
      <li class:expired={eventItem.start_time < now}>
        <span class="number">#{index + 1}</span>
        <span class="weekday">{eventItem.weekday}</span>
        <span class="date">{eventItem.date}</span>
        <span class="duration">{eventItem.begin} - {eventItem.end}</span>
      </li>
       {/each}
    </ul>
  </div>
  {:else}
  <div class="course-events-main">
    <h3>Die nächsten Termine:</h3>
    <ul>
      {#each upcomingEventItems as eventItem, index}
        <li>
          <span class="number">#{index + 1}</span>
          <span class="weekday">{eventItem.weekday}</span>
          <span class="date">{eventItem.date}</span>
          <span class="duration">{eventItem.begin} - {eventItem.end}</span>
        </li>
      {/each}
    </ul>
  </div>
  {/if}

  <div class="course-events-footer">
    <span>Download:</span>
    <span><a href="https://tanzschule-leipzig.nimbuscloud.at/index.php?c=PublicCustomers&a=Export&onlineCourse={item.onlineCourseId}&format=pdf" target="_blank" role="button" class="course-events-btn">PDF</a></span>
    <span><a href="https://tanzschule-leipzig.nimbuscloud.at/index.php?c=PublicCustomers&a=Export&onlineCourse={item.onlineCourseId}&format=ical" target="_blank" role="button" class="course-events-btn">iCal</a></span>
  </div>
</div>

<style type="text/scss">
  @import '../../config/mixins-svelte';
  .course-events {
    background: rgba($white, 0.1);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 0.75rem 0.5rem;
    font-size: 0.875rem;

    @include respond-to-min($md) {
      font-size: 1rem;
      padding: 2rem 1rem;
    }
  }
  .course-events-header {
    margin-bottom: 1rem;
    > div:first-child {
      padding-bottom: 0.5rem;
      border-bottom: rgba($white, 0.15) 1px solid;
    }
    > div:last-child {
      padding-top: 0.5rem;
    }
    @include respond-to-min($ml) {
      margin-bottom: 2rem;
      display: flex;
      > div:first-child {
        flex: 0 0 60%;
        padding: 0 1rem 0 0;
        border: none;
      }
      > div:last-child {
        flex: 0 0 40%;
        padding: 0 0 1rem 0;
      }
    }
    span {
      display: block;
    }
    .break {
      @include respond-to-min($sm) {
        display: none;
      }
    }
    .course-title {
      font-weight: 300;
      font-size: 1.4rem;
      @include respond-to-min($md) {
        font-size: 2rem;
        margin-top: -0.5rem;
      }
    }
    .course-options {
      margin-top: 0.5rem;
    }

    .course-running-info {
      color: #f4aa37;
      margin: 0.5rem 0;
      font-weight: 400;
    }
    .location-name {
      @include respond-to-min($md) {
        font-size:1.25rem
      }
    }
  }

  .course-events-main {
    ul {
      display: table;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    li {
      display: table-row;
      &:nth-of-type(odd) {
        background: rgba($black, 0.15);
      }
      &.expired {
        color:rgba($white, 0.4);
      }
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 300;
    }
    span {
      display: table-cell;
      line-height: 1;
      padding: 0.5rem;
      &:last-of-type {
        width: 100%;
      }
    }
  }
  .course-events-footer {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    span {
      display: block;
      margin-right: 0.5rem;
    }
    a.course-events-btn {
      display: block;
      padding: 0.5rem 1rem;
      border-radius: 3px;
      background: #3d9015;
      color: $white;
      text-decoration: none;
      font-size: 0.9rem;
      line-height: 1;
    }
  }
</style>
