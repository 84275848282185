<script>
  import { createEventDispatcher } from 'svelte';
  import MyDate from "../../utilities/dateUtils";

  export let eventItem;

  const now = Date.now()
  const startTime = new MyDate(parseInt(eventItem.start) * 1000);
  const endTime = new MyDate(parseInt(eventItem.end) * 1000);
  const dispatch = createEventDispatcher();

  const openForm = () => {
    dispatch('booking', {
      isOpen: true,
      courseId: eventItem.courseId,
      onlineCourseId:eventItem.onlineCourseId
    });
  }

  let runningState, bookable;
  if (eventItem.itemType === 'courseEvent') {
    runningState = (eventItem.timestamp >= now) ? 'upcoming' : 'running';
    bookable = (eventItem.registrationPossible === true);
  }
</script>


<div class="events-item eventlist-events-item">

  {#if (eventItem.itemType === 'courseEvent')}
    <div class="events-item-date">
      <div class="events-item-date-inner" class:highlighted={(eventItem.itemType === 'ticketEvent')}>
        <span class="date">{startTime.date()}</span>
        <span class="month short">{startTime.weekday()}</span>
        <span class="month full">{startTime.weekdayFullName()}</span>
      </div>
    </div>
    <div class="events-item-info">
      <h2 class="title headline_3" class:highlighted={(eventItem.itemType === 'ticketEvent')}>{eventItem.title} - {eventItem.locationName}</h2>
      <span class="location">{eventItem.locationRoom}</span>
      <span class="duration">{startTime.hour()}:{startTime.minute()} - {endTime.hour()}:{endTime.minute()} Uhr</span>
    </div>

    <div class="events-item-booking">
      {#if eventItem.registrationPossible === true}
        <button class="btn-booking normal" on:click|preventDefault|stopPropagation={openForm}>buchen</button>
      {:else}
        <button class="btn-booking" class:booked-out={!bookable}>ausgebucht</button>
      {/if}
    </div>
  {/if}

  {#if (eventItem.itemType === 'ticketEvent')}
    <div class="events-item-date">
      <div class="events-item-date-inner highlighted">
        <span class="date">{startTime.date()}</span>
        <span class="month short">{startTime.weekday()}</span>
        <span class="month full">{startTime.weekdayFullName()}</span>
      </div>
    </div>

    <div class="events-item-info">
      <h2 class="title headline_3 highlighted">{eventItem.title}</h2>
      <span class="location">{eventItem.locationName}</span>
      <span class="duration">{startTime.hour()}:{startTime.minute()} - {endTime.hour()}:{endTime.minute()} Uhr</span>
    </div>

    <div class="events-item-booking">
      <a href="{eventItem.ticketsLink}" target="_blank" class="btn-booking normal">Tickets</a>
    </div>
  {/if}
</div>